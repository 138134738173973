.mt-3 strong {
  white-space: normal;
}
.repeating-dates {
  max-height: 400px;
  overflow-y: scroll;
}
.cursor-pointer,
.repeating-dates span {
  cursor: pointer;
}
/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
